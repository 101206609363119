
* {
    margin: 0;
}
#root {
  height: 100%;
}
html,
body {
    height: 100%;
    background-color: black !important;
    color: white;
    overflow: hidden;
}

.container {
    height: 100%;
    display: flex;
  }
.contentSection {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 20px 0;
 }
 .footerWrapper { 
    background-color:#fff;
     text-align:center;
    padding:0.9rem;
    font-size:1rem;
    font-weight:bold;
    }
.contentSectionMiddle{
    display: flex;
    flex:3;
    align-self:flex-end;
}

 .contentSectionLeft {
     display: flex;
     flex: 1;
 }
 .contentSectionRight {
    flex: 1;
    background-color: transparent;
    z-index: 1;
}

 .container {
    background: black;
    -webkit-transition: background 2s; /* For Safari 3.0 to 6.0 */
    transition: background 2s; /* For modern browsers */
}


.container.data-id-2 {
    background: black ;
}
.container.data-id-3 {
    background:#34cceb ;
}
.container.data-id-4 {
    background: #FECC01 ;
}
.container.data-id-5 {
    background: #E82967 ;
}
#main-container-wrapper[pagename="home"]{
    background: black ;
}
#main-container-wrapper[pagename="us"]{
    background: #663300 ;
}
#main-container-wrapper[pagename="contact"]{
    background: #E82967 ;
    
}
#main-container-wrapper[pagename="portfolio"]{
    background: #004d99 ;
    
}
#main-container-wrapper[pagename="process"]{
    background: black ;

}
.white{
    color: white;
}
.gray{
    color: gray;
}

.large{
    font-size:3rem;
       font-weight:300px;
       color: rgb(255, 255, 255);
       font-family: Arial, Helvetica, sansSerif;
}

.medium{
    font-size:1.875rem;
    color: #BABABA;
    font-family: Montserrat;
    line-height: 1.25rem;
    letter-spacing: 0px;
    font-weight: 200;
   }
.small{
    font-family: Montserrat;
    line-height: 1rem;
    letter-spacing: 0px;
    font-weight: 200;
    font-size: 1rem;
}

/* box  */
.box-left {
    display: flex;
    margin-top: 10%;
    flex: 1;
    justify-content: space-around;
    max-width: 80%;
    float: right;
    align-self: center;
    flex-direction: column;
}
.box-right {
    display: flex;
    align-self: self-start;
    flex-direction: column;
}

header.ant-layout-header {
    background: transparent;
}
section.ant-layout {
    background: transparent;
}
.componentContainer{
    flex: 1;
  }
 .SecondHeadline{
  font-family: Montserrat;
  line-height: 4.6875rem;
  letter-spacing: 0px;
  font-weight: 200;
  font-size: 2.4rem;
  /* color: rgb(186, 186, 186); */
  color: #fff;
}
.FirstHeadline {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    line-height: 4.5rem;
    letter-spacing: 0px;
    font-weight: 300;
    font-size: 4.5rem;
    margin: 0px;
    border-radius: 0px;
    padding: 0px;
    color: rgb(255, 255, 255);
    text-decoration: none;
}
.NormalText {
    font-size: .9rem;
    color: #fff;
    margin-bottom: 0px;
    font-family: Montserrat;
    letter-spacing: 0px;
    line-height: 2.4rem;
    margin: 20px 0px;
  }
  .SmallText{
    font-size: 0.68rem;
    color:#fff;
    font-family: Montserrat;
    letter-spacing: 0px;
    line-height: 1.5rem;
  }
  .address-text{
    font-size: 0.9rem;
    color:#fff;
    font-family: Montserrat;
    letter-spacing: 0px;
    line-height: 1.5rem;
  }
.site-layout-background {
    padding: 0px;
    min-height: 380px;
}
.site-layout{
    padding: 0 50px;
    margin-top: 64px;
}

.BackButtonWrapper .BackbtnLink svg {
    z-index: 9999;
}

@media only screen and (max-width : 767px) {

   header {
        width: 80%;
    }
    .ant-layout-footer {
        height: 9vh;
    }
    .contendsection {
         max-width: 90%;
    }
    .ant-layout-header {
        padding: 0 0px;
    }
    .navHeaderWrapper .logoWrappersection .logoWrapper {
        top: -52%;
        left: 1%;
        height: none;
    }
    .navHeaderWrapper .logoWrappersection .logoWrapper #CODEGRABS-logo {
        width: 40%;
    } 
      .emptySapce {
        display: none ;
      }
      .ant-modal-content {
        width: 100vw;
        height: 100vh;
        background: #FFC733;
        z-index: 999;
        padding: 11% 8%;
        color: #fff;
    }
    .ant-modal-content a {
        color: #fff;
        font-size: 1.7rem;
        line-height: 3.5rem;
    }
    .ant-modal {
        width: 100%;
        padding: 0px;
        height: 100vh;
        top: 0;
        position: absolute;
        margin: 0px;
    }
    .ant-modal-footer {
        display: none;
    }
    .menu-sec{
        position: absolute;
        top: 48%;
        right: -12%;
        z-index: -1;
    }

    .menuUl{
        padding-left: 10rem;
        z-index: 999;
    }

    .menuUl i{
      display: block !important;
      color: #fff !important;
      /* margin-top: 1rem !important; */
      position: absolute;
      right: 19%;
      top: 25%;
      /* left: 76% */
      padding-right: 0%;
    }

  .menuUl .menuLi {
      display: none !important;
  }

  .fa, .fas {
    font-weight: 900 !important;
  }

  /* home page */
  .SecondHeadline{
    font-size: 14px;
    line-height: 1.6875rem;
    letter-spacing: 0px;
    font-family: none;
    }
    #homepage .imageStackContainer {
        margin-bottom: -5rem !important;
        max-width: 2px;
    }
    #homepage .imageStackContainer .imageStack:nth-child(1){
        top: -24%;
        left: 0%;
        margin-left: 4rem;
        margin-top: 0;
    }
    #homepage .imageStackContainer .imageStack:nth-child(2) {
        left: 5.7rem;
        top: -18%;
    }

    .FirstHeadline{
        font-size: 1.25rem;
        line-height: 15px;
    }
    .homeMiddle{
        padding-top: 6vh;
    }
    .homeNextbutton {
        position: absolute;
        left: 20%; 
    }
    .contentSectionLeft .contentSectionRight {
        margin-top: 0;
    }
    /* about us */

    .site-layout{
        padding: 0 4%;
        margin-top:1%
    }
    .aboutsection{
        padding-top: 6vh;
    }
    .aboutsection .descriptionsection {
        padding-top: 7%;
    }
    .NormalText {
        font-size: 0.8rem ;
        line-height: 1.2rem ;
        width: 90%;
    }
    .contentSection {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 20px 0;
    }
    .SecondHeadline{
        font-size: 14px;
        line-height: 1.6875rem;
        letter-spacing: 0px;
        font-family: none;
    }
    .FirstHeadline{
        font-size: 1.25rem;
        line-height: 15px;
    }
    #woldMap-wrapper {
        height: fit-content ; 
    }
    #woldMap-wrapper svg {
        width: 80% ;
        height: 50% ;
        align-items: center;
        margin-left: 23px;
    }
     .aboutsection .AboutNextbtn {
        position: absolute;
        top: 95%;
        left: 14%;
        z-index: 999;
     }
    /* Process */
    /* first stage */
    .contendsection .AboutNextbtn{
        position: absolute;
        top: 95%;
        right: 87%;
        z-index: 999;
    }
    .contendsection .processBackbtn {
        z-index: 999;
    }
    .imageStackContainer.process{
        margin-left: 6rem;
        position: relative;
    }
    .imageStackContainer.process .imageStack:nth-child(1){
        width: 21vw;
        top: -13%;
        left: -14%;
    }
    .imageStackContainer.process .imageStack:nth-child(2){
        width: 28vw;
        top: -7%;
        left: 8%;
    }
    /* Second stage */
    #new-imageStackContainer{
        margin-top: 14vh;
    }
    .imageStack:nth-child(1){
        width: 31vw;
        margin-left: 1rem;
    }
    .imageStack:nth-child(2){
        width: 31vw;
        margin-top: 0px;
    }
    .imageStack:nth-child(3){
        width: 31vw;
    }
    .imageStack:nth-child(4){
        width: 31vw;

    }
    /* third stage */
    .blob {
        right: 133px;
        top: 30%; 
    }
    .codetext{
        font-size: 0.52rem;
        margin-top: 0;
        max-height: 40vh;
        overflow: hidden;
    }
    /* portpolio */
    .contentSectionLeft-left{
        flex: none;
    }
    .portpolio{
        padding-top: 6vh;
    }
    .portpolio .NormalText{
        width: auto;
    }
    .portpolio .portpolioMiddle .contendsection .descriptionsection .SmallText {
        font-size: 0.578rem;
        line-height: 1rem;
    }
    .contendsection{
        padding-right: 0px;
        padding: 0 0 ;
    }
    #process-page .contendsection{
        margin-top: 6vh;
    }
    .imageStackPortpolioContainer{
        padding-top: 4vh;
    }
    .imageStackPortpolioContainer .imageStack:nth-child(1) {
        height: 17%;
        width: 126px;
        z-index: 0;
        top: -11%;
        margin-left: 3rem !important;
    }
    .imageStackPortpolioContainer .imageStack:nth-child(2){
        z-index: 1;
        width: 23vw;
        top: -37%;
        left: 58%;
        margin-left: 4.3rem;
    }
    /* .portpolio .portfoliotNextbtn{
        margin-top: 33%;
    } */
    .nextBtnWrapper .Nextbtn{
        position: absolute;
        top: 1%;
        right: 31%;
    }
    /* 2nd page */
    .contentSectionRight{
        margin-top: 7rem;
    }
    .contentSectionRight .imageStackContainer .imageStack:nth-child(1){
        top: 33%;
        left: 2.9rem;
        top: 34%;
    }
    .contentSectionRight .imageStackContainer .imageStack:nth-child(2){
        left: 5.7rem;
        top: 12%;
    }
    .contentSectionRight .imageStackContainer .imageStack:nth-child(3){
        left: 7.7rem;
    }
    .contentSectionRight .imageStackContainer .imageStack:nth-child(4){
        left: 9.7rem;
    }
    .imageStack:nth-child(5){
        width: 31vw;
        left:11rem;
    }
    .nextButtonWrapper .NextbtnLink svg{
        position: absolute;
        top: -11%;
        right: 29%;
        z-index: 1000;
    }

    /* contact */
    #contact-page .box-right {
    padding-top: 1vh !important;
    padding-left: 0rem; 
}
.contact{
    padding-top: 6vh;
}
 
}

@media only screen and (min-width: 768px) and (max-width : 1024px) {
    /* For general iPad layouts */
    /* home page */
    .navHeaderWrapper .menuUl {
        list-style: none;
        flex: 2;
    }
    .navHeaderWrapper .logoWrappersection .logoWrapper #CODEGRABS-logo {
        width: 94%;
    }
    .navHeaderWrapper .logoWrappersection .logoWrapper {
        left: 1%;
    }
    .contentSectionLeft-left{ 
        flex: none;
    }
     #homepage .imageStackContainer .imageStack:nth-child(1){
        left: 2rem;
        top: -76%;
    }
    #homepage .imageStackContainer .imageStack:nth-child(2){
        left: 4rem;
        top: -81%;

    } 
    #homepage .imageStackContainer .imageStack:nth-child(3){
        left: 6rem;
        top: -80%;
    } 
    #homepage .imageStackContainer .imageStack:nth-child(4){
        left: 8.2rem;
        top: -79%;
    } 
    #homepage .imageStackContainer .imageStack:nth-child(5){
        left: 10rem;
        top: -79%;
    } 
    .homeNextbutton {
        position: absolute;
        left: 20%; 
        z-index: -1;
    }

    /* About us */
    .SecondHeadline{
        font-size: 1.2rem;
    }
    .FirstHeadline {
        font-size: 3.5rem;
    }
    .componentContainer .ant-col-md-8{
        display: none;
    }
    .contendsection {
        padding-left: 0px;
        padding-right: 0px;
    }
    #woldMap-wrapper{
        opacity: 1;
        position: absolute;
        left: -24%;
    }
    #woldMap-wrapper svg {
        width: 142%;
        height: 119%;
    }
    
    /* process */
    /* 1st stage */
    .contendsection .AboutNextbtn{
        position: absolute;
        top: 101%;
        left: 4rem;
        z-index: 999;
    }
    .imageStackContainer.process {
        top: 23%;
        left: 20%;
    }
    #process-page .contendsection{
        padding: 0px;
    }
    #process-page .ant-col-md-8 {
        display: none;
    }
    .imageStackContainer.process img.imageStack{
        width: 15vh;
    }
    .imageStackContainer.process .imageStack:nth-child(1){
        margin-top: -186px;
    }
    .imageStackContainer.process .imageStack:nth-child(2){
        margin-top: -234px;
        height: 36vh;
        width: 27vw;
    }
    /* 2nd stage */
    #new-imageStackContainer {
        margin-top: 27vh;
    }
    /* 3rd stage */
    .blob {
        top: 24%;
    }
    /* portpolio */
    /* 1st page */
    .nextBtnWrapper .Nextbtn{
        position: absolute ;
        top: 84%;
        right: 30%;
    
    }
    /* 2nd stage */
    .portpolio .portfoliotNextbtn {
        margin-top: 0px;
    }
    .nextButtonWrapper .NextbtnLink svg{
        position: absolute;
        top: 94%;
        right: 33%;
        z-index: 999;

    }
    .portpolio .imageStackContainer {
        padding-top: 0vh;
    }
    .portpolio .imageStackContainer .imageStack:nth-child(1){
        left: -1rem;
        top: -23%;
    }
    .portpolio .imageStackContainer .imageStack:nth-child(2){
        left: 1.5rem;
        top: -22%;
    }
    .portpolio .imageStackContainer .imageStack:nth-child(3){
        left: 4.2rem;
        top: -16%;
    }
    .portpolio .imageStackContainer .imageStack:nth-child(4){
        left: 7rem;
        top: -12%;
    }
    .portpolio .imageStackContainer .imageStack:nth-child(5){
        left: 8.7rem;
        top: -10%;
    }
  }

  @media only screen and (max-height: 768px) and (min-height:600px) and (min-width:900px){
    .SecondHeadline {
        line-height: 2.6875rem;
        font-size: 1.5rem;
    }
    .NormalText {
    line-height: 1.9rem;
    }
    .FirstHeadline {
        line-height: 2.5rem;
        font-size: 2.5rem;
    }
    .portpolio{
        padding-top: 14vh;
    }
  }

  @media only screen and  (min-width:827px) and (max-width:925px){
    .portpolio {
        padding-top: 9rem;
    }
    #homepage .imageStackContainer{
        position: absolute;
        top: 70%;
    }
  }
